<template>
	<div class="w-100 d-flex flex-column flex-md-row">
		<div class="input-group" v-if="!alreadyHasVehicle">
			<select name="year" aria-label="Vehicle Year" class="form-select" v-model="selectedYear" placeholder=""
					style="min-width: 100px;">
				<option selected disabled :value="null">Year</option>
				<option v-for="year in years" :key="year" :value="year">{{ year }}</option>
			</select>


			<select name="make" aria-label="Vehicle Make" :disabled="selectedYear == null" class="form-select"
					style="min-width: 100px;" v-model="selectedMake">
				<option selected disabled :value="null">Make</option>
				<option v-for="make in makes" :key="make.id" :value="make.id">{{ make.label }}</option>
			</select>

			<select name="model" aria-label="Vehicle Model" :disabled="selectedMake == null" class="form-select"
					style="min-width: 120px;" v-model="selectedModel">
				<option selected disabled :value="null">Model</option>
				<option v-for="model in models" :key="model.id" :value="model.id">{{ model.label }}</option>
			</select>



			<button :disabled="selectedModel == null" class="btn btn-outline-danger text-white" type="button"
					v-if="alreadyHasVehicle && modifiedData == false && !isMobile" @click="clear"
					aria-label="Clear Vehicle" title="Clear Vehicle">
				<i class="bi bi-x fw-bold text-danger"></i>
			</button>
		</div>
		<div v-else class="text-center">
      <div v-if="quickSwitcherOptions.length > 0">
        <div class="input-group">
            <span class="input-group-text"><i class="bi bi-car-front-fill me-1"></i></span>
            <select class="form-select" aria-label="Select Vehicle" v-model="quickSwitcherValue">
              <option v-for="option in quickSwitcherOptions" :key="option.id" :value="option.id">{{ option.label }}</option>
            </select>
          </div>
      </div>
      <div v-else>
        <i class="bi bi-car-front-fill me-1"></i>
        <span class=" fw-normal me-2">{{ `${selectedVehicle.year} ${selectedVehicle.make_name}
          ${selectedVehicle.model_name}` }}</span>

        <button class="btn btn-xs text-black p-1" type="button" @click="clear" aria-label="Clear Vehicle"
            title="Clear Vehicle">
          <i class="bi bi-x-octagon text-danger"></i>

        </button>
      </div>
		</div>


		<!-- <button :disabled="selectedModel == null" class="btn btn-outline-danger text-black w-100 mt-2" type="button"
				v-if="alreadyHasVehicle && modifiedData == false && isMobile" @click="clear" aria-label="Clear Vehicle"
				title="Clear Vehicle">
			<i class="bi bi-x fw-bold text-danger"></i>
			Clear
		</button> -->
	</div>
</template>

<script>
export default {
	el: '#vehicle-selector-compact',
	data() {
		return {
			selectedYear: null,
			selectedModel: null,
			selectedMake: null,
			quickSwitcherOptions: [],
			quickSwitcherValue: null,
			years: [],
			models: [],
			makes: [],
			alreadyHasVehicle: false,
			selectedVehicle: null, // This should only be set if alreadyHasVehicle is true
			modifiedData: false,

			loading: true,


		}
	},
	mounted() {
		if (window.vrdata?.vehicle?.model_id != 0) {
			let vehicle = window.vrdata.vehicle;

			// this.selectedYear = vehicle.year;
			// this.selectedMake = vehicle.make_id;
			// this.selectedModel = vehicle.model_id;
			this.selectedVehicle = vehicle;

			this.alreadyHasVehicle = true;
			this.modifiedData = false;
		}

    if (window.vrdata?.vehicleQuickSwitcherOptions) {
      this.quickSwitcherOptions = window.vrdata.vehicleQuickSwitcherOptions;
      this.selectedMake = this.selectedVehicle?.make_id;
      this.quickSwitcherValue = this.selectedVehicle?.model_id;
    }

		setTimeout(() => {
			this.loading = false;
		}, 1000);


	},
	created() {
		this.fetchData('years');
		this.modifiedData = false;
		this.loading = true;


	},
	methods: {
		submit() {
			window.location = this.submitHref;
		},

		clear() {
			this.alreadyHasVehicle = false;
			this.fetchData('reset');
			this.$emit('cleared');

			this.selectedYear = null;
			this.selectedMake = null;
			this.selectedModel = null;


			// fetch('/ymm-json.php?request=reset').then((response) => {
			// 	let url = new URL(window.location.href);
			// 	url.searchParams.delete('class_9');
			// 	url.searchParams.delete('class_8');
			// 	url.searchParams.delete('class_year');
			// 	url.searchParams.delete('universal');
			// 	window.location.replace(url.toString());
			// });

		},
		fetchData(requestType) {
			fetch(`/ymm-json.php?` + new URLSearchParams({
				request: requestType,
				year: this.selectedYear,
				make: this.selectedMake
			}))
				.then(response => response.json())
				.then(data => {
					console.log("fetched " + this.selectedMake)
					if (data.error) {
						console.error(data.error)
						return
					}

					if (requestType === 'years') {
						this.years = data.data.map(year => year.id)
					} else if (requestType === 'makes') {

						this.makes = data.data.map(make => {
							return {
								id: make.id,
								label: make.label,
							}
						})
					} else if (requestType === 'models') {
						this.models = data.data.map(model => {
							return {
								id: model.id,
								label: model.label,
							}
						})
					}
				})
		},

	},
	watch: {
    quickSwitcherValue(newValue, oldValue) {
      if (this.loading) {
        return;
      }
      this.selectedModel = newValue;
      this.modifiedData = true;
      this.submit();
    },
		selectedYear(newValue, oldValue) {
			if (this.loading) {
				return;
			}
			this.fetchData('makes');
			this.selectedMake = null;
			this.modifiedData = true;

		},
		selectedMake(newValue, oldValue) {
			if (this.loading) {
				return;
			}
			this.fetchData('models');
			this.selectedModel = null;
			console.log("selectedMake")
			this.modifiedData = true;

		},
		selectedModel(newValue, oldValue) {
			if (this.loading) {
				return;
			}
			this.modifiedData = true;
			this.submit();
		},

	},
	computed: {
		submitHref() {
			var url = new URL(window.location.href);
			url.searchParams.set("class_8", this.selectedMake);
			url.searchParams.set("class_9", this.selectedModel);
      if (this.selectedYear) {
			  url.searchParams.set("class_year", this.selectedYear);
      }
			return url.href;
		},
		isMobile() {
			return window.innerWidth < 768;
		},
	},
}
</script>

<style lang="scss" scoped></style>